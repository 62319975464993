import React from "react"
import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HubSpotForm from "../components/HubSpotForm"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

i18n.changeLanguage("pl")

const Kontakt = () => {
  const { t } = useTranslation()

  return (
    <Layout
      seo={{
        title: "Darmowa wycena optymalizacji i pozycjonowania strony • WeNet",
        description:
          "Skorzystaj z darmowej wyceny optymalizacji i pozycjonowania Twojej strony WWW • Wypełnij formularz i zadbaj o wysoką pozycję strony w Google",
        hreflangs: {
          pl: "kontakt/",
          en: "en/contact/",
          de: "de/kontakt/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/kontakt/",
                name: t("seo-title-contact"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              { url: t("link-url-contact"), text: t("link-text-contact") },
            ]}
          />
        </div>
        <h1 className="title text-center">Darmowa wycena optymalizacji i pozycjonowania strony</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue" style={{ marginTop: "30px" }}>
                Chcesz otrzymać darmową wycenę? Wypełnij formularz i zadbaj o wysoką pozycję Twojej strony w Google.
                Skontaktujemy się z Tobą i przedstawimy szczegóły oferty – zupełnie za darmo.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Formularz zgłoszeniowy</h2>
              <script charSet="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>
              <HubSpotForm
                formId={
                  devMode === "true" ? "65052656-4e8a-4f27-8f64-4b7ed3a2fa9a" : "2460850c-9826-4a62-a6e5-ce1d6917a1ad"
                }
                formType="contact"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18" style={{ marginTop: "40px" }}>
                Zostaw swoje dane, a w zamian otrzymasz:
                <br />
                <br />
              </p>
              <ul>
                <li>
                  dokładną wycenę usług pozycjonowania i optymalizacji – będziesz wiedzieć, w co inwestujesz swoje
                  pieniądze – u nas nie ma ukrytych kosztów,
                </li>
                <li>
                  szczegóły oferty – dowiesz się, co dokładnie składa się na ofertę przygotowaną przez nasz zespół –
                  mówimy wprost, co możemy zaproponować Twojej firmie,
                </li>
                <li>
                  dostęp do specjalisty – nawiążesz kontakt z polecanymi fachowcami SEO – chętnie dzielimy się wiedzą i
                  doradzamy rozwiązania dopasowane do potrzeb naszych klientów,
                </li>
                <li>
                  przewagę nad konkurencją – dowiesz się, co możesz poprawić, by Twoja strona prześcignęła konkurentów –
                  zadbamy o lepszą widoczność Twojej strony w wyszukiwarce.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Kontakt
